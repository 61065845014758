import { FC } from 'react';
import clsx from 'clsx';
import { useAvatar, Props } from './useAvatar';
import styles from './Avatar.module.css';

export const Avatar: FC<Props> = (props) => {
  const { src, name, meta, size, reverse } = useAvatar(props);
  const wrapperClass = clsx(
    styles.wrapper,
    size === 'sm' && styles.size,
    size === 'lg' && styles.lg,
    size === 'xl' && styles.xl,
    reverse && styles.avatarRight
  );

  if (name) {
    return (
      <div className={styles.container}>
        <div className={wrapperClass}>
          <img src={src} className={styles.avatar} alt="" />
        </div>
        <div>
          <div className={styles.name}>{name}</div>
          {meta && <div className={styles.meta}>{meta}</div>}
        </div>
      </div>);

  }

  return (
    <div className={wrapperClass}>
      <img src={src} className={styles.avatar} alt="" />
    </div>);

};