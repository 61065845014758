export interface Props {
  src: string
  name?: string
  meta?: string
  size?: 'sm' | 'lg' | 'xl'
  reverse?: boolean
}

export const useAvatar = (props: Props) => {
  const { src, name, meta, size, reverse } = props

  return {
    src,
    name,
    meta,
    size,
    reverse,
  }
}
